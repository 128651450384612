.slide {
  transform: scale(0.8);
  transition: 0.5s;
  opacity: 0.6;
}

.activeSlide {
  transition: transform 0.5s;
  position: relative;
  z-index: 1000;
  width: 200% !important;
  transform: translateX(-25%);
  height: 220% !important;
}

.activeSlide1 {
  transition: transform 0.5s;
  transform: scale(1);
  opacity: 1;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.scroll-to-top-btn {
  align-items: center;
  border-radius: 50% !important;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  border-color: #f8f9fa;
  color: #f8f9fa;
  background-color: transparent;
  border: 1px solid transparent;
  background: white;
  cursor: pointer;
}

@keyframes rose {
  0% {
    transform: rotate(0deg) translate(-15px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-15px) rotate(-360deg);
  }
}

@media (max-width: 1024px) {
  .slick-prev {
    margin-left: 25px;
  }

  .slick-next {
    margin-right: 25px;
  }
}

[class^="number-slide"],
[class*=" number-slide"] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
  max-height: 100vh;
}

.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}
.scene {
  width: 256px;
  height: 200px;
  perspective: 1000px;
  position: relative;
}
.carousel {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: visible;
  transform: translateZ(-288px);
  transform-style: preserve-3d;
}
.carousel__cell {
  position: absolute;
  width: 256px;
  left: 10px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.keen-slide-image {
  width: 256px;
}

@media (min-width: 1024px) {
  .scene {
    width: 356px;
    height: 400px;
  }

  .carousel__cell {
    width: 356px;
    height: 457px;
  }

  .keen-slide-image {
    width: 356px;
  }
}

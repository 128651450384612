@font-face {
  font-family: "Jakarta Sans";
  src: url("assets/fonts/PlusJakartaSans-Light.woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Jakarta Sans";
  src: url("assets/fonts/PlusJakartaSans-Regular.woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Jakarta Sans";
  src: url("assets/fonts/PlusJakartaSans-Medium.woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Jakarta Sans";
  src: url("assets/fonts/PlusJakartaSans-Bold.woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nasalization";
  src: url("assets/fonts/Nasalization-Regular.otf");
  font-style: normal;
  font-weight: 500;
}
